import { WMBaseItem } from "./baseitem";
const Camur = require("@/utils/camurutils").default;
import Network from "@/networking";
const Utils = require("../utils").default;

export class Node extends WMBaseItem {
  get channels() {
    return this.locals.channels;
  }
  get serial() {
    return this.dto.serialNumber;
  }
  get gridX() {
    return this.locals.gridX;
  }
  set gridX(val) {
    return (this.locals.gridX = val);
  }
  get gridY() {
    return this.locals.gridY;
  }
  set gridY(val) {
    return (this.locals.gridY = val);
  }
  get posX() {
    return this.locals.posX;
  }
  set posX(val) {
    return (this.locals.posX = val);
  }
  get posY() {
    return this.locals.posY;
  }
  get posZ() {
    return this.locals.posZ;
  }
  set posY(val) {
    return (this.locals.posY = val);
  }

  get coords_text() {
    return `XYZ: ${this.posX}, ${this.posY}, ${this.posZ}`;
  }

  get capability() {
    // TODO: this should be capability
    return this.dto.nodeType;
  }
  get versionMajor() {
    return this.dto.versionMajor;
  }
  get versionMinor() {
    return this.dto.versionMinor;
  }
  get version_full() {
    // Leading 0
    let versionMinor = String(this.dto.versionMinor).padStart(2, '0');
    return this.dto.versionMajor + "." + versionMinor;
  }
  get version_float() {
    return (
      parseFloat(this.dto.versionMajor) +
      parseFloat(this.dto.versionMinor) * 0.01
    );
  }
  get version_supported() {
    let node_support = Camur.get_node_version_supported(this);
    if (node_support == Camur.node_version_support.ok) {
      return true;
    } else {
      return false;
    }
  }

  get status() {
    return "Standby";
  }

  get item_type() {
    return "node";
  }

  get is_power_supply() {
    return Camur.is_power_supply(this.capability);
  }

  get ps_last_measured_voltage() {
    // PSU
    if (!this.channels.hasOwnProperty("I0")) return "?";
    return this.last_measured_for_channel(this.channels["I0"]);
  }

  get ps_last_measured_current() {
    // PSI
    if (!this.channels.hasOwnProperty("I1")) return "?";
    return this.last_measured_for_channel(this.channels["I1"]);
  }

  get connected() {
    return this.dto.connected;
  }

  get lastseen() {
    return this.dto.lastSeen;
  }

  get_last_seen_string() {
    return window.app.langutil.print_date_time_sec(this.lastseen);
  }

  get_cntrl() {
    let zone = window.appm.get_zone(
      this.app_prop.project_id,
      this.app_prop.zone_id
    );

    if (zone == null) return null;

    return window.appm.get_cntrl(
      this.app_prop.project_id,
      zone.dto.controllerId
    );
  }

  get_cntrl_serial() {
    let controller = this.get_cntrl();

    if (controller == null) return null;

    return controller.dto.serialNumber;
  }

  get_cntrl_version() {
    let controller = this.get_cntrl();

    if (controller == null) return null;

    if (controller.dto.serialNumber > 0) {
      // Controller assigned
      let version = controller.dto.versionMajor + controller.dto.versionMinor * 0.01;
      return version;
    } else {
      return 0.0;
    }
  }

  save_grid_position(gridX, gridY) {
    this.locals.gridX = gridX;
    this.locals.gridY = gridY;
  }

  live_val_index(channel_no) {
    return `${this.dto.id}_${channel_no}`;
  }

  add_live_value(value, time_stamp, channel_no) {
    if (isNaN(value)) {
      console.error(
        `Live value for node ${this.name}(${this.serial
        }) is Nan! Channel: ${channel_no} Value: ${value}`
      );
      value = 0;
    }
    let index = this.live_val_index(channel_no);
    // console.log(`add_live_value for node ${this.name}(${this.serial
    //}) Channel: ${channel_no} Value: ${value}`);
    if (!this.app_prop.live_vals.hasOwnProperty(index)) {
      // Note: since nodes always send live values in pairs of 2,
      // P-nodes sends "ghost"-samples from a mysterious channel no 1,
      // which does not exist. So we just ignore these samples.
      return;
    }
    let time = new Date(time_stamp);

    this.app_prop.live_vals[index].push({
      value,
      minDate: time
    });

    if (this.app_prop.live_vals[index].length > 120) {
      this.app_prop.live_vals[index].shift();
    }
    // this.save_live_values_to_localStorage();
  }

  // save_live_values_to_localStorage() {
  //   if (typeof localStorage.live_values === "undefined") {
  //     localStorage.live_values = {};
  //   }
  //   localStorage[this.cross_prj_identifier] = JSON.stringify(
  //     this.app_prop.live_vals
  //   );
  //   // console.log(`save_live_values_to_localStorage ${this.cross_prj_identifier})`);
  //   // TODO Save held values
  // }

  // load_live_values_to_localStorage() {
  //   if (
  //     typeof localStorage.live_values === "undefined" ||
  //     !localStorage.live_vals.hasOwnProperty(this.cross_prj_identifier)
  //   ) {
  //     return;
  //   }

  //   let live_val_json = localStorage.live_vals[this.cross_prj_identifier];
  //   this.app_prop.live_vals = JSON.parse(live_val_json);
  //   // Load Save held values
  // }

  get cross_prj_identifier() {
    return `${this.app_prop.project_id}_${this.dto.id}`;
  }

  last_recorded_for_channel(channel) {
    let lastValue = channel.lastValue;
    if (isNaN(lastValue))
      return "NaN"
    else if ((lastValue == "Infinity") || (lastValue == "-Infinity"))
      return lastValue;
    else {
      var numberStr = lastValue.toFixed(channel.formula.decimals);
      var decimalPointStr = ".";
      var localDecimalPointStr = window.app.settings.decimal_point;
      var valueStr = numberStr.replace(decimalPointStr, localDecimalPointStr);

      return `${valueStr} ${channel.formula.unit}`;
    }
  }

  last_depolarization_value_for_channel(channel) {
    let lastValue = channel.lastDepolarizationValue;
    if (isNaN(lastValue))
      return "NaN";
    else if ((lastValue == "Infinity") || (lastValue == "-Infinity"))
      return lastValue;
    else if (channel.lastDepolarizationValueUpdated < "2020-01-01")
      return "-";
    else {
      var numberStr = lastValue.toFixed(channel.formula.decimals);
      var decimalPointStr = ".";
      var localDecimalPointStr = window.app.settings.decimal_point;
      var valueStr = numberStr.replace(decimalPointStr, localDecimalPointStr);

      return `${valueStr} ${channel.formula.unit}`;
    }
  }
  last_depolarization_value_updated_for_channel(channel) {
    if (channel.lastDepolarizationValueUpdated > "2020-01-01")
      return channel.lastDepolarizationValueUpdated;
    else
      return "-";
  }


  last_measured_for_channel(channel, maxLength = 30) {
    let result = null;
    let live_val_sample = null;

    if (this.app_prop.live_vals != null) {
      let channel_identifier = `${channel.nodeId}_${channel.no}`;

      let data = this.app_prop.live_vals[channel_identifier];
      live_val_sample = data[data.length - 1];
    }

    let dto_last_value_sample = {
      value: channel.lastValue,
      date: new Date(channel.lastValueUpdated)
    };

    result = dto_last_value_sample.value;

    if (live_val_sample != null) {
      if (live_val_sample.date > dto_last_value_sample.date)
        result = live_val_sample.value;
    }

    let valueAsStr = "?";

    if (result != null) {
      if (isNaN(result))
        valueAsStr = "NaN";
      else {
        let resultAsStr = result.toFixed(channel.formula.decimals);
        // Avoid strange formatted values such as 
        // 2.5873592333750418e+35 mV, and replace them with ?
        if (resultAsStr.length < maxLength) {
          valueAsStr = `${resultAsStr} ${channel.formula.unit}`;
        }
      }
    }
    return valueAsStr;
  }

  constructor(dto, identification) {
    super(dto);
    this.app_prop.zone_id = identification.zone_id;
    this.app_prop.project_id = identification.project_id;
    this.app_prop.live_vals = {};
    this.app_prop.held_vals = {};

    for (let channel in this.channels) {
      let channel_identifier = `${this.dto.id}_${this.channels[channel].no}`;
      this.app_prop.live_vals[channel_identifier] = [];
      this.app_prop.held_vals[channel_identifier] = null;
    }

    if (dto.serialNumber != null) {
      this.app_prop.serial_decoded = dto.serialNumber;
    } else {
      this.app_prop.serial_decoded = "N/A";
    }
  }

  props_template() {
    return {
      include_select: false,
      output_on: false,
      zone_id: 0,
      project_id: 0
    };
  }

  children_template() {
    return {
      channels: []
    };
  }

  upload_changes() {
    let changes = this.changes();

    return Network.node.update(
      this.app_prop.project_id,
      this.app_prop.zone_id,
      this.id,
      changes
    );
  }

  change_zone(zone_id) {
    this.locals.zoneId = zone_id;
    this.upload_changes();
  }

  assign_device(device_serial) {
    this.submit_device_change(device_serial);
  }

  hold_values() {
    for (let ch in this.app_prop.live_vals) {
      if (this.app_prop.live_vals[ch].length > 0) {
        let last_value = this.app_prop.live_vals[ch][
          this.app_prop.live_vals[ch].length - 1
        ];
        //console.log("hold_values:"+ch+"="+JSON.stringify(last_value));
        this.app_prop.held_vals[ch] = last_value;
      }
    }
  }
  clear_hold_values() {
    for (let ch in this.app_prop.live_vals) {
      if (this.app_prop.live_vals[ch].length > 0) {
        let last_value = this.app_prop.live_vals[ch][
          this.app_prop.live_vals[ch].length - 1
        ];
        //console.log("clear_hold_values:"+ch+"="+JSON.stringify(last_value));
        this.app_prop.held_vals[ch] = null;
      }
    }
  }

  copy_settings() {
    let result = {};
    let props_to_copy = [
      "psOutputOn",
      "psOutputRecovery",
      "psCurrentLimit",
      "psFixedVoltage",
      "logTemperature",
      "psMode",
      "ctMode",
      "ctZraRange",
      "ctResMesRange",
      "ctResMesFreq",
      "channels",
      "sectionNo"
    ];

    for (let prop in this.locals) {
      if (props_to_copy.includes(prop)) {
        result[prop] = this.locals[prop];
      }
    }
    result["capability"] = this.capability;
    return result;
  }

  paste_settings(settings) {
    if (settings.capability != this.capability) {
      window.appm.cannot_paste_settings_different_type(
        settings.capability,
        this.capability
      );
      return;
    }

    for (let prop in settings) {
      if (prop == "channels") {
        for (let channelIndex in settings["channels"]) {
          if (this.channels.hasOwnProperty(channelIndex)) {
            this.paste_channel_settings(
              settings.channels[channelIndex],
              this.channels[channelIndex]
            );
          }
        }
      } else {
        this.locals[prop] = settings[prop];
      }
    }
  }

  paste_channel_settings(source_channel, target_channel) {
    let props_not_to_paste = [
      "id",
      "no",
      "name",
      "updated",
      "isInput",
      "isOutput",
      "created"
    ];

    for (let prop in source_channel) {
      if (!props_not_to_paste.includes(prop))
        target_channel[prop] = source_channel[prop];
    }
  }

  get_live_values(interval, duration) {
    if (this.serial == null) {
      // There is no point in trying to get live values
      // if there's no device conntected
      return;
    }

    let cntrl_serial = this.get_cntrl_serial();
    if (cntrl_serial == null) {
      window.appm.on_require_cntrl_device();
      return;
    }
    window.app.msg_manager.get_live_values_for_node(
      this,
      cntrl_serial,
      duration,
      interval
    );
  }

  submit_zone_change() {
    return Network.node.update(
      this.app_prop.project_id,
      this.app_prop.zone_id,
      this.id,
      {
        zoneId: this.locals.zoneId
      }
    );
  }

  submit_device_change(new_serial) {
    // TODO: internationalize
    let msg = new_serial ? `Device with serial ${new_serial} assigned to ${this.name}` : `Unassign device on ${this.name}`;

    if (new_serial == null) new_serial = 0;

    return Network.node.update(
      this.app_prop.project_id,
      this.app_prop.zone_id,
      this.id,
      {
        serialNumber: new_serial,
        lastUpdate: this.dto.updated
      },
      () => window.appm.show_note(msg)
    );
  }

  static new_item_instance(dto, identification) {
    return new Node(dto, identification);
  }

  static find_parent(app, identification) {
    return app.$appm.get_zone(identification.project_id, identification.zone_id)
      .nodes;
  }

  static find(app, id, identification) {
    var n = app.$appm.get_node(identification.project_id, identification.zone_id, id);
    return n;
  }

  static update_channel(response, app, identification) {
    // Not yet implemented. Refreshing node instead
  }
}
