import LZString from "lz-string";

const UNIX_TIME_1990 = 631152000;
const COMPRESSION_RADIX = 36;
const REPLACEMENT_DICT = [
  ['"]]},{"channelId":', "\n"],
  [',"data":[["', "="],
  ['","', ";"],
  ['"],["', " "],
  ["],[", "$"],
  ['",', "@"],
  ['{"series":[{"channelId":', "<"],
  ['"]]}]}', ">"],
  ['{"series":[]}', "&"]
];
const VALUE_DECIMAL_POINTS = 4;
const META_ARRAY_KEY = "C3_rc_meta";

export default class RecordingCache {
  constructor(project_id, cache_invalidation_days) {
    this.project_id = project_id;
    this.cache_invalidation_days = cache_invalidation_days;
  }

  has_data_for_dates(hash_params) {
    let hash = this.make_hash(hash_params);
    if (window.localStorage.getItem(hash) == null) {
      return false;
    }

    let metadata = this.get_meta_for_hash(hash);
    if (metadata && this.cache_is_valid(metadata)) {
      return true;
    } else {
      // Delete the cached data if we have no metadata about it
      window.localStorage.removeItem(hash);
      return false;
    }
  }

  make_hash(hash_params) {
    let text = btoa(JSON.stringify(hash_params));
    let hash = 0;

    for (var i = 0; i < text.length; i++) {
      var character = text.charCodeAt(i);
      hash = (hash << 5) - hash + character;
      //hash = hash & hash; // Convert to 32bit integer
    }
    return "C3_r_" + (hash + this.project_id).toString(32);
  }

  store(hash_params, data) {
    let hash = this.make_hash(hash_params);
    //console.log(`Store hash ${hash}`)
    let compressed_data = this.compress_data(data);
    let json = JSON.stringify(compressed_data);
    let compressed_json = this.compress_json_string(json);

    let successfully_stored = false;
    let max_tries = 10;
    let tries = 0;
    while (!successfully_stored) {
      try {
        window.localStorage.setItem(hash, compressed_json);
        successfully_stored = true;
      } catch (error) {
        if (tries === max_tries) {
          console.error(
            "Can not cache data! Failed to clear cache " + tries + " times!"
          );
          return;
        }
        //console.log("Cache is full! Attempting to make room")
        this.create_room_in_cache(250000);
        tries++;
      }
    }

    let meta = {
      c: this.compress_int(Math.round(Date.now() / 1000)),
      s: compressed_json.length
    };
    this.save_to_meta_data(hash, meta);
  }

  create_room_in_cache(room_needed) {
    let meta_data = this.get_meta_data();
    let to_delete = meta_data.sort((a, b) =>
      this.decompress_int(a.c) > this.decompress_int(b.c) ? 1 : -1
    );
    let hashes_to_remove = [];
    let characters_deleted = 0;
    let delete_index = 0;
    while (
      characters_deleted < room_needed &&
      delete_index < to_delete.length
    ) {
      hashes_to_remove.push(to_delete[delete_index].h);
      characters_deleted += to_delete[delete_index].s;
      delete_index++;
    }

    for (let item of hashes_to_remove) {
      window.localStorage.removeItem(item);
    }

    //console.log(`Freed up ${characters_deleted * 2} bytes of localStorage`);
    meta_data = meta_data.filter(md => !hashes_to_remove.includes(md.h));
    window.localStorage.setItem(META_ARRAY_KEY, JSON.stringify(meta_data));
  }

  get_meta_data() {
    let meta_string = window.localStorage.getItem(META_ARRAY_KEY);
    let metadata = [];
    if (meta_string != null) {
      metadata = JSON.parse(meta_string);
    }
    return metadata;
  }

  get_meta_for_hash(hash) {
    return this.get_meta_data().find(md => md.h === hash);
  }

  save_to_meta_data(hash, value) {
    value.h = hash; // Save hash in data
    let data = this.get_meta_data();
    let index = data.findIndex(md => md.h === hash);
    if (index != -1) {
      data[index] = value;
    } else {
      data.push(value);
    }
    window.localStorage.setItem(META_ARRAY_KEY, JSON.stringify(data));
  }

  load(hash_params) {
    let hash = this.make_hash(hash_params);
    //console.log(`Load hash ${hash}`)
    let txt = window.localStorage.getItem(hash);
    let json = this.decompress_str_to_json(txt);
    return this.decompress_data(JSON.parse(json));
  }

  cache_is_valid(metadata) {
    let days_since_creation =
      (Date.now() / 1000 - this.decompress_int(metadata.c)) / 60 / 60 / 24;
    return days_since_creation < this.cache_invalidation_days;
  }

  compress_json_string(json) {
    let compressed_json = this.replace_with_dict(json, REPLACEMENT_DICT, false);
    return LZString.compressToUTF16(compressed_json);
  }

  decompress_str_to_json(str) {
    return this.replace_with_dict(
      LZString.decompressFromUTF16(str),
      REPLACEMENT_DICT,
      true
    );
  }

  replace_with_dict(str, replacement_dict, reverse = false) {
    for (let rep_pair of replacement_dict) {
      let to_find = rep_pair[reverse ? 1 : 0];
      let to_insert = rep_pair[reverse ? 0 : 1];
      try {
        str = str.replaceAll(to_find, to_insert);
      } catch (e) {
        while (str.includes(to_find)) {
          str = str.replace(to_find, to_insert);
        }
      }
    }
    return str;
  }

  compress_data(data) {
    let compressed_series = [];
    for (let series of data.series) {
      compressed_series.push({
        ...series,
        data: series.data.map(d => this.compress_data_point(d))
      });
    }

    return {
      ...data,
      series: compressed_series
    };
  }

  decompress_data(compressed_data) {
    let decompressed_series = [];
    for (let series of compressed_data.series) {
      decompressed_series.push({
        ...series,
        data: series.data.map(d => this.decompress_data_point(d))
      });
    }

    return {
      ...compressed_data,
      series: decompressed_series
    };
  }

  compress_data_point(datapoint) {
    if (datapoint.length != 5) {
      console.warn(datapoint);
    }

    return [
      this.compress_int(this.compress_date(datapoint[0])),
      this.compress_int(this.compress_date(datapoint[1])),
      this.compress_value(datapoint[2]),
      this.compress_value(datapoint[3]),
      this.compress_value(datapoint[4])
    ];
  }

  decompress_data_point(datapoint) {
    return [
      this.decompress_date(this.decompress_int(datapoint[0])),
      this.decompress_date(this.decompress_int(datapoint[1])),
      this.decompress_value(datapoint[2]),
      this.decompress_value(datapoint[3]),
      this.decompress_value(datapoint[4])
    ];
  }

  compress_value(val) {
    return this.compress_int(
      Math.round(
        parseFloat((+val).toFixed(VALUE_DECIMAL_POINTS)) *
          10 ** VALUE_DECIMAL_POINTS
      )
    );
  }

  decompress_value(val) {
    return this.decompress_int(val) / 10 ** VALUE_DECIMAL_POINTS;
  }

  compress_date(iso_date_string) {
    let unix_tstamp = Math.floor(new Date(iso_date_string).getTime() / 1000);
    return unix_tstamp;
  }

  decompress_date(unix_stamp) {
    let js_tstamp = unix_stamp * 1000;
    return new Date(js_tstamp).toISOString().split(".")[0] + "Z";
  }

  compress_int(num) {
    return num.toString(COMPRESSION_RADIX);
  }

  decompress_int(num) {
    return parseInt(num, COMPRESSION_RADIX);
  }
}
