<template>
  <div class="none_label">
    <span class="alert_text" v-if="text != ''">{{ text }}</span>
    <span v-else>{{ $txt.no_data }}</span>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    }
  }
};
</script>

<style>
.none_label {
  text-align: center;
  padding: 20px;
  background-color: #efefef;
  border-radius: 4px;
}

.alert_text {
  font-weight: bold;
  font-size: 18px;
  color: red;
  padding-left: 7px;
}
</style>
